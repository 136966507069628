
const sidebarShow = (state = true, action) => {
  switch (action.type) {
    case 'SHOW':
      return true;
      case 'HIDE':
        return false;
      case 'TOGGLE':
      return !state;
    default:
      return state;
  }
}

export default sidebarShow;